<template>
  <section v-if="panel && equipmentNotification">
    <div class="container" v-if="$can('view', 'NotificacoesEscrita')">
      <div class="box box-warning">
        <div class="nav-tabs-custom">
          <ul class="nav nav-tabs pull-right ui-sortable-handle">
            <!-- /Tabs -->
            <li
              v-if="panel && subPanelOptions('EquipmentAlarmNotificationPanel')"
            >
              <a
                class="equipment-data-panel-tab"
                href="#equipment-alarm-notification-panel"
                aria-expanded="true"
                data-toggle="tab"
              >
                <i class="fa fa-bell"></i>
              </a>
            </li>
            <li
              class="active"
              v-if="panel && subPanelOptions('EquipmentNotificationPanel')"
            >
              <a
                class=""
                href="#equipment-notification-panel"
                aria-expanded="true"
                data-toggle="tab"
              >
                <i class="fa fa-inbox"></i>
              </a>
            </li>
            <!-- \tabs -->

            <!-- /Toolbar -->
            <li class="pull-left header">
              <i class="fa fa-share-square"></i> {{ title }}
            </li>
            <!-- \Toolbar -->
          </ul>
          <div v-if="!panel" class="tab-content panel-content">
            <div class="overlay">
              <i class="fa fa-refresh fa-spin"></i>
            </div>
          </div>
          <div v-else class="tab-content panel-content">
            <div
              v-if="subPanel('EquipmentAlarmNotificationPanel')"
              id="equipment-alarm-notification-panel"
              class="tab-pane"
            >
              <EquipmentAlarmNotificationPanel
                v-bind:equipment="equipment"
                v-bind:display="display"
                v-bind:panel="subPanel('EquipmentAlarmNotificationPanel')"
                v-bind:defaultOptions="equipmentNotification"
              >
              </EquipmentAlarmNotificationPanel>
            </div>

            <div
              v-if="subPanel('EquipmentNotificationPanel')"
              id="equipment-notification-panel"
              class="tab-pane active"
            >
              <EquipmentNotificationPanel
                v-bind:equipment="equipment"
                v-bind:display="display"
                v-bind:panel="subPanel('EquipmentNotificationPanel')"
                v-model="equipmentNotification"
              >
              </EquipmentNotificationPanel>
            </div>
          </div>
        </div>
      </div>
    </div>
    <InfoBox v-else preset="unauthorized" />
  </section>
</template>
<script>
import EquipmentDashboardBase from "@/views/private/DashboardEquipmentBase.vue";
import InfoBox from "@/components/info-box.vue";
import EquipmentNotificationPanel from "@/components/equipment-notification-panel";
import EquipmentAlarmNotificationPanel from "@/components/equipment-alarm-notification-panel";
import Panels from "@/assets/dashboard/panels.json";
export default {
  name: "DashboardEquipmentNotificationEditor",
  extends: EquipmentDashboardBase,
  components: {
    InfoBox,
    EquipmentNotificationPanel,
    EquipmentAlarmNotificationPanel
  },
  data: () => {
    return {
      tab: "#equipment-notification-panel",
      equipmentNotification: null
    };
  },
  computed: {
    title: function () {
      return this.tab == "#equipment-notification-panel"
        ? this.$t("notification_configuration")
        : this.$t("alarm_configuration");
    },
    panelOptions: function () {
      var panel = this.panel || null;
      return (panel && panel.options) || null;
    },
    panel: function () {
      // var self = this;
      // var lst = ((self.display && self.display.panels) || []).filter(function(
      //   i
      // ) {
      //   return (
      //     ("name" in i && "panelName" in self && i.name === self.panelName) ||
      //     ("name" in i && i.name == self.$options.name)
      //   );
      // });
      // return (lst.length && lst[0]) || null;

      let panel = null;
      if (this.display && (this.panelName || this.$options.name)) {
        panel = (this?.display?.panels || []).find(
          ({name}) => name == this.panelName || name == this.$options.name
        );
        if (!panel) {
          let panelCfg = (Panels || []).find(
            ({name}) => name == this.panelName || name == this.$options.name
          );
          if (panelCfg) {
            panel = JSON.parse(JSON.stringify(panelCfg.template));
          }
        }
      }
      return panel;
    }
  },
  watch: {
    templateState(n, o) {
      if (o == "loading" && n == "ready") {
        this.setDisplay();
      }
    }
  },
  methods: {
    subPanel(name) {
      var self = this;
      var panelOptions = self.panelOptions;
      if (panelOptions) {
        if ("panels" in panelOptions) {
          var lst = (panelOptions.panels || []).filter(function (i) {
            return (
              ("name" in i && i.name == name) ||
              ("template" in i && i.template == name)
            );
          });
          return (lst.length && lst[0]) || {};
        }
      }
      return null;
    },
    subPanelOptions(name) {
      var self = this;
      var subPanel = self.subPanel(name);
      return (subPanel && subPanel.options) || null;
    }
  },
  mounted() {
    let self = this;
    $('a[data-toggle="tab"]').on("shown.bs.tab", function (e) {
      self.tab = $(e.target).attr("href");
    });
  },
  created() {
    let dft =
      this?.$root?.config?.equipment_extended_properties?.notifications || {};
    dft = {
      timeout: dft.timeout ?? 5,
      groups: (dft?.groups ?? []).length
        ? [...dft?.groups]
        : [...Array(5).keys()].map((i) => ({
            name: `${this.$tc("group", 1)} ${i + 1}`,
            tags: [],
            fixed: false
          })),
      unit: dft?.unit || "min"
    };
    this.$set(this, "equipmentNotification", dft);
  }
};
</script>

<style lang="css">
.box {
  margin: 0;
  padding: 0;
  box-shadow: none;
}

.nav-tabs-custom {
  margin: 0;
  padding: 0;
  box-shadow: none;
}
</style>
